<!--    <div class='RegCode'></div>-->
<!--    注册码-->
<!--todo
1.关键字搜索客户端编码失败-->

<template>
    <div class="RegCode" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                class="pform"
                :inline="true"
                v-model="form"
                size="medium"
                label-position="right"
            >
                <el-form-item label="机构组">
                    <el-select
                        placeholder=""
                        v-model="form.deptGroupCode"
                        filterable
                        clearable
                        @change="form.deptCode = ''"
                    >
                        <el-option label="请选择" value="" />
                        <el-option :label="s.name" :value="s.code" v-for="s in meta.groupDepts" :key="s.code" />
                    </el-select>
                </el-form-item>
                <el-form-item label="注册机构">
                    <el-select placeholder="" v-model="form.deptCode" filterable clearable>
                        <el-option label="请选择" value="" />
                        <el-option :label="s.name" :value="s.code" v-for="s in metaDepts" :key="s.code" />
                    </el-select>
                </el-form-item>
                <el-form-item label="类型">
                    <el-select placeholder="注册码类型" v-model="form.type">
                        <el-option label="请选择" value="" />
                        <el-option label="Win7/Linux x64" value="0" />
                        <el-option label="Win7/Linux x32" value="1" />
                        <el-option label="安卓收银机" value="3" />
                    </el-select>
                </el-form-item>
                <el-form-item style="float: right" label="客户端编码" align="right">
                    <el-input v-model="form.search" placeholder="客户端编码/cpu序列号" />
                    <el-button
                        class="left10"
                        type="primary"
                        @click="handleManualQuery"
                        size="small"
                        v-if="hasPrivilege('menu.system.regCode.open')"
                    >
                        查询
                    </el-button>
                    <br />
                    <el-button
                        type="primary"
                        @click="handleCreate"
                        size="small"
                        v-if="hasPrivilege('menu.system.regCode.create')"
                        >新建
                    </el-button>
                </el-form-item>
                <br />
                <el-form-item>
                    <el-radio-group
                        v-model="form.status"
                        @change="
                            () => {
                                form.page = 1;
                                handleQuery();
                            }
                        "
                    >
                        <el-radio-button :label="1">使用中</el-radio-button>
                        <el-radio-button :label="2">未使用</el-radio-button>
                        <el-radio-button :label="3">已作废</el-radio-button>
                        <el-radio-button :label="4">全部</el-radio-button>
                    </el-radio-group>
                </el-form-item>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <reg-code-table :table-data="tableData" @enable="handleReset" @disable="handleDisabled"></reg-code-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                :current-page="form.page"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
        <el-dialog title="新建注册码" :visible.sync="create.show" width="20%">
            <el-form ref="crt" :model="create.reg" :rules="create.rules" size="small">
                <el-form-item label="数量" prop="count">
                    <el-input type="number" v-model.number="create.reg.count" step="1" />
                </el-form-item>
                <br />
                <el-form-item label="类型">
                    <el-select v-model="create.reg.type">
                        <el-option value="0" label="Win7/Linux x64" />
                        <el-option value="1" label="Win7/Linux x32" />
                        <el-option value="3" label="安卓" />
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="create.show = false" size="small">取 消</el-button>
                <el-button type="primary" @click="handleCreateOk" size="small">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';
import RegCodeTable from 'views/menu/system/component/RegCodeTable';

export default {
    name: 'RegCode',
    components: { RegCodeTable },
    data() {
        return {
            form: {
                deptGroupCode: '',
                deptCode: '',
                type: '',
                search: '',
                status: '1', //1使用中,2未使用,3已作废
                page: 1,
                limit: Util.Limit,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            tableData: [],
            depts: [],
            url: {
                page: '/biz/clientReg/pageExtend',
                disable: '/biz/clientReg/disable',
            },
            meta: {
                groupDepts: [],
            },
            create: {
                show: false,
                url: '/biz/clientReg/createExtend',
                reg: {
                    type: '0',
                    count: 10,
                },
                rules: {
                    count: [{ type: 'integer', min: 1, message: '请输入正整数数量', trigger: 'blur' }],
                },
            },
        };
    },
    computed: {
        metaDepts() {
            if (!this.meta.groupDepts.length || !this.form.deptGroupCode) {
                return [];
            }
            return this.meta.groupDepts.find((a) => a.code == this.form.deptGroupCode)['depts'] || [];
        },
    },
    mounted() {
        this.$efApi.deptGroupApi.companyAllGroupAndDept(3).then((rst) => {
            this.meta.groupDepts = rst;
        });
        this.handleQuery();
    },

    methods: {
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleManualQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        handleExport() {},
        handleDisabled(code) {
            UrlUtils.DeleteRemote(
                this,
                this.url.disable,
                code,
                '确认作废本条数据吗?作废后该注册码激活的客户端需要重新激活'
            );
        },
        handleReset(code) {
            UrlUtils.DeleteRemote(this, this.url.disable, code, '确认恢复本条数据吗?');
        },
        handleCreate() {
            this.create.reg.count = 10;
            this.create.show = true;
            this.create.reg.type = '0';
        },
        handleCreateOk() {
            const _this = this;
            this.$refs.crt.validate((valid) => {
                if (!valid) return;
                UrlUtils.PostRemote(this, this.create.url, this.create.reg, null, () => {
                    _this.$message.success('保存成功');
                    _this.create.show = false;
                    _this.handleQuery();
                });
            });
        },
    },
};
</script>

<style>
.RegCode .el-tabs__header {
    margin-bottom: 0;
}
</style>
